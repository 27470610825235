import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueTranslate from "vue-translate-plugin";
import VueNoty from "vuejs-noty";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import VueRouter from "vue-router";
import Vco from "v-click-outside";
import VueSweetalert2 from "vue-sweetalert2";
import VueClipboard from "vue-clipboard2";
import VueTheMask from "vue-the-mask";
import ViaCep from "vue-viacep";
import Paginate from "vuejs-paginate";
import App from "./App.vue";
import router from "./router/routes";
import store from "./state/store/index.js";
import "./state/helpers/index.js";

import "@/design/index.scss";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vco);
Vue.use(require("vue-moment"));
Vue.use(VueTranslate);
Vue.use(VueNoty, {
  theme: "sunset",
});
Vue.use(VueNoty);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.use(VueClipboard);
Vue.component("paginate", Paginate);
Vue.use(VueTheMask);
Vue.use(ViaCep);

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);

import 'primeicons/primeicons.css';

import firebase from "firebase/app";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
